import _ from "lodash";
import { LoadScript } from "../common";
import $ from "jquery";
import "bootstrap";
import sheetrock from "sheetrock";
import { createSpan, endSpan } from "../opentelemetry/otel-instrumentation";
try {
  $.fn.sheetrock = sheetrock;
} catch (ignore) {
  /* empty */
}

//import handlebars from 'handlebars';
import * as handlebars from "handlebars/dist/handlebars";

const {
  allowInsecurePrototypeAccess,
} = require("@handlebars/allow-prototype-access");

const insecureHandlebars = allowInsecurePrototypeAccess(handlebars);

export default async function initSlideShow(parent) {
  const span = createSpan('initSlideShow', parent)
  await import("./slideshow.css");
  //import 'googletagmanager';
  LoadScript(
    span,
    "https://www.googletagmanager.com/gtag/js?id=UA-173390879-1",
    true
  );

  window.dataLayer = window.dataLayer || [];

  function gtag(parent) {
    const span = createSpan('gtag', parent)
    dataLayer.push(arguments);
    endSpan(span)
  }
  gtag(span, "js", new Date());
  gtag(span, "config", "UA-173390879-1");

  $(document).ready(function () {
    LoadMarketing(span);
    LoadMarketingTest(span);
    //$("#carouselIndicators").carousel({ interval: 8000 });
  });
  endSpan(span)
}

function LoadMarketingTest(parent) {
  const span = createSpan('LoadMarketingTest', parent)
  const template = $("#hr-template-teste").html();
  if (template) {
    //var HRTemplate = handlebars.compile($('#hr-template-teste').html());
    const HRTemplate = insecureHandlebars.compile(template);

    const mySpreadsheet =
      "https://docs.google.com/spreadsheets/d/1arBHi4qgjWqXbLjRczX2IsJt9o46z_9PX0Xp-p1lYlc/edit#gid=0";

    const sql = "select A, B where B = 'Ativo'";

    $("#teste").sheetrock({
      url: mySpreadsheet,
      query: sql,
      labels: ["MENSAGEM", "STATUS"],
      rowTemplate: HRTemplate,
    });
  }
  endSpan(span)
}

function LoadMarketing(parent) {
  const span = createSpan('LoadMarketing', parent)
  const template = $("#hr-template").html();
  //var HRTemplate = handlebars.compile($('#hr-template').html());
  if (template) {
    const HRTemplate = insecureHandlebars.compile(template);

    const mySpreadsheet =
      "https://docs.google.com/spreadsheets/d/1hsS5PCgEtXyreSlhEdGMp2kcYLqZ-5JCX8iy2PIfquk/edit#gid=0";

    const sql = "select B, C, D, E, F, G where G = 'Ativo'";

    $("#hr").sheetrock({
      url: mySpreadsheet,
      query: sql,
      labels: ["TITULO", "CHAMADA", "LINK", "IMAGEM", "DESTAQUE", "STATUS"],
      rowTemplate: HRTemplate,
    });
  }
  endSpan(span)
}

async function TryInit(parent) {

  let scriptElementInit = document.getElementById("slideshow-script-init");
  if (scriptElementInit) {
    const span = createSpan('TryInit', parent)
    await initSlideShow(span);
    endSpan(span)
  }
}

await TryInit(window.mainSpan);
