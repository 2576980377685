import {
    BatchSpanProcessor
} from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { Resource } from '@opentelemetry/resources';
import { context, trace } from '@opentelemetry/api';

let flushTimer;

const resource = new Resource({
    'service.name': 'Login Page',
    'service.version': '1.0.0',
    'environment': 'production',
    'host': document.location.host,
});

const exporter = new OTLPTraceExporter({
    url: 'http://otlpteste2.megaerp.online/v1/traces',
    headers: { "Authorization": "Basic b3RscC1tZWdhLWNsb3VkLXB1YmxpYzpvdGxwLW1lZ2EtY2xvdWQtcHVibGlj" }
});

const provider = new WebTracerProvider({
    resource: resource,
    spanProcessors: [
        new BatchSpanProcessor(exporter)
    ]
});

provider.register({
    contextManager: new ZoneContextManager(),
});

registerInstrumentations({
    instrumentations: [],
});

const tracer = provider.getTracer('Login Page');

export function createSpan(name, parent) {
    const ctx = trace.setSpan(context.active(), parent);
    const span = tracer.startSpan(name, undefined, ctx);
    span.setAttribute('host', document.location.host);
    return span
}

function scheduleFlush() {
    if (flushTimer) return; 
    flushTimer = setTimeout(async () => {
        await provider.forceFlush();
        flushTimer = null; 
    }, 1); 
}

export function endSpan(span) {
    span.end();
    scheduleFlush(); 
}


